<template>
  <div class="no-select"
    style="position: relative; width: 100vw; overflow: hidden; display: flex; align-items: center; justify-content: center; flex-direction: row; background-size: cover;"
    :style="{ height: `${windowHeight}px`, backgroundImage: `url(${require('../assets/images/bgkaraoke.png')})` }">







    <div style="height: 100%; flex: 1; position: relative;">







      <div style="z-index: 100; position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden;">
        <KaraokeflixPlayer ref="player" mode="karaoke" @interface="interface" />
      </div>


      <div class="qr-wrapper font-dark">
        <p>Confira a lista<br>de músicas</p>
        <qrcode-vue :value="'https://digitaloke.com.br'" :size="100" level="H" />
      </div>


      <div :class="!loaded && this.queue.length === 0 ? 'featured-input' : 'headed-input'">


        <!--DIGITS-->


        <div class="digits" :style="{ display: showDigits || !loaded ? 'flex' : 'none' }">
          <div class="digit-wrapper">
            <input id="digit-0" type="number" min="0" max="9" maxlength="1" placeholder="0" autocomplete="off"
              readonly="readonly" :class="digitsCursorPosition === 0 ? 'active-digit' : ''"
              @keydown="(e) => e.preventDefault()" @keypress="(e) => e.preventDefault()" />
          </div>

          <div class="digit-wrapper">
            <input id="digit-1" type="number" min="0" max="9" maxlength="1" placeholder="0" autocomplete="off"
              readonly="readonly" :class="digitsCursorPosition === 1 ? 'active-digit' : ''"    
              @keydown="(e) => e.preventDefault()" @keypress="(e) => e.preventDefault()" />
          </div>

          <div class="digit-wrapper">
            <input id="digit-2" type="number" min="0" max="9" maxlength="1" placeholder="0" autocomplete="off"
              readonly="readonly" :class="digitsCursorPosition === 2 ? 'active-digit' : ''"
              @keydown="(e) => e.preventDefault()" @keypress="(e) => e.preventDefault()" />
          </div>

          <div class="digit-wrapper">
            <input id="digit-3" type="number" min="0" max="9" maxlength="1" placeholder="0" autocomplete="off"
              readonly="readonly" :class="digitsCursorPosition === 3 ? 'active-digit' : ''"
              @keydown="(e) => e.preventDefault()" @keypress="(e) => e.preventDefault()" />
          </div>

          <div class="digit-wrapper">
            <input id="digit-4" type="number" min="0" max="9" maxlength="1" placeholder="0" autocomplete="off"
              readonly="readonly" :class="digitsCursorPosition === 4 ? 'active-digit' : ''"
              @keydown="(e) => e.preventDefault()" @keypress="(e) => e.preventDefault()" />
          </div>

          <div class="digit-wrapper">
            <input id="digit-5" type="number" min="0" max="9" maxlength="1" placeholder="0" autocomplete="off"
              readonly="readonly" :class="digitsCursorPosition === 5 ? 'active-digit' : ''"
              @keydown="(e) => e.preventDefault()" @keypress="(e) => e.preventDefault()" />
          </div>

        </div>



        <!--SONGTITLE-->
        <div class="songtitle"
          :class="this.digitsCursorPosition === null && this.codeResult !== null ? 'active-title' : ''"
          v-html="getTitle()">
        </div>



      </div>




      <div v-if="this.creditsMode"
        style="z-index: 102; position: absolute; bottom: 20px; right: 20px; overflow: hidden; display: flex;">
        <span class="label-no-credits" v-if="this.credits < 1 && !this.loaded">INSERIR CRÉDITO</span>
        <span class="label-credits" v-if="this.credits > 0">{{ this.credits }} {{ this.credits > 1 ?
          'CRÉDITOS' : 'CRÉDITO' }}</span>
      </div>

      <!-- <div v-if="!this.creditsMode"
        style="z-index: 102; position: absolute; bottom: 20px; right: 20px; overflow: hidden; display: flex;">
        <span class="label-no-credits">MODO LIVRE</span>
      </div> -->



      <!--INTRO-->
      <div v-if="!playing && showIntro" class="intro-wrapper"
        style="background-color: #000; z-index: 1000; position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; display: flex; align-items: center; justify-content: center; flex-direction: column; background-size: cover; background-position: bottom center ;"
        :style="{ backgroundImage: `url(${require('../assets/images/init-bg.jpg')})` }">

        <img class="img-fluid" src="../assets/images/logo/logo-seu-canto-em-qualquer-canto.png" alt style="margin: 0 auto; max-width: 50%; -webkit-filter: drop-shadow(0 0 1rem #fff);
         filter: drop-shadow(0 0 1rem #fff);" />

        <p class='intro-featured'
          style="margin: 100px 0 0 0; color: #000; font-size: 25px; letter-spacing: 15px; font-weight: 300; text-shadow: 0px 0px 5px #fff;">
          PROFESSIONAL BRAZILIAN KARAOKE</p>

        <p class='intro-site'
          style="margin: 50px 0 0 0; color: #000; font-size: 22px; letter-spacing: 2px; font-weight: 300; position: absolute; left: 50%; bottom: 15px; transform: translate(-50%, 0%);">
          www.karaokeflix.com.br</p>
      </div>


      <!--NEXT SONG-->
      <div v-if="showNextSong" class='nextsong-wrapper'
        style="background-color: #000; z-index: 1000; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  overflow: hidden; display: flex; align-items: center; justify-content: space-between; flex-direction: column; background-size: cover;"
        :style="{ backgroundImage: `url(${require('../assets/images/mic-bg.jpg')})` }">
        <div style="position: absolute; top: 50px; left: 0; max-width: 50vw;">
          <img class="img-fluid" src="../assets/images/next-song.png" alt style="margin: 0 auto;" />
        </div>

        <div
          style="color: #000; position: absolute; top: 0; bottom: 0; left: 0; right: 0; max-width: 100%; padding: 30px; text-align: center; display: flex; flex-direction: column; justify-content: center;">
          <h1 class="f-w-900" style="font-size: 100px;">{{ this.currentSong[2] }}</h1>
          <h2 style="font-size: 60px;">{{ this.currentSong[0] }}</h2>
        </div>

        <div style="position: absolute; bottom: 50px; right: 0; max-width: 60vw;">
          <img class="img-fluid" src="../assets/images/next-song-footer.png" alt style="margin: 0 auto;" />
        </div>
      </div>





      <!--SCORE-->
      <div v-if="!playing && showScore && scoreMode"
        style="background-color: #000; color: #000; z-index: 1000; position: relative; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; display: flex; align-items: center; justify-content: space-between; flex-direction: column; background-size: cover;"
        :style="{ backgroundImage: `url(${require('../assets/images/mic-bg.jpg')})` }">


        <video ref="bg" autoplay
          style="width: 100%; height: 100%; object-fit: cover; object-position: center center; position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden; z-index: 0;">
          <source :src="scoreVideo" type="video/mp4">
        </video>



        <div style="z-index: 1; margin-top: 22vh;" class="animated-div score-wrapper"
          :class="{ fadeOut: shouldFadeOutScore }">
          <p style="font-weight: 900; font-size: 30px; color: #fff; text-align: center; margin-bottom: 0;">SUA NOTA
          </p>
          <div id="counter"
            style="font-size: 30vh; line-height: 30vh; font-weight: 900; text-align: center; color: #fff">{{ counter
            }}
          </div>
        </div>

        <img class="img-fluid" src="../assets/images/logo/logo-simple-red-black.png" alt style="margin: 0 auto 30px; max-width: 200px; -webkit-filter: drop-shadow(0 0 1rem #fff);
         filter: drop-shadow(0 0 1rem #fff);" />

      </div>








    </div>



    <div class="virtual-keyboard-karaoke" :class="{ 'open': this.vkopen }">
      <div class="virtual-keyboard-inner">
        <div class="virtual-keyboard-numbers">
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '1' })">1</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '2' })">2</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '3' })">3</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '4' })">4</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '5' })">5</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '6' })">6</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '7' })">7</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '8' })">8</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '9' })">9</div>
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: '0' })">0</div>

        </div>
        <div class="virtual-keyboard-controls">

          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'M' })">
            <i class="fa fa-volume-up"></i> +
            <br>
            Vol. + (M)
          </div>


          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'A' })">
            <i class="fa fa-music"></i> +
            <br>
            Tom + (A)
          </div>

          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'K' })">
            <i class="fa fa-tachometer"></i> +
            <br>
            Vel. + (K)
          </div>

          
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'W' }); this.vkopen = false;">
            <i class="fa fa-step-backward"></i>
            <br>
            Reiniciar (W)
          </div>

          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'U' })">
            <i class="fa fa-eraser"></i>
            <br>
            Limpar (U)
          </div>
        </div>
        <div class="virtual-keyboard-controls">
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'N' })">
            <i class="fa fa-volume-down"></i> -
            <br>
            Vol. - (N)
          </div>
         
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'V' })">
            <i class="fa fa-music"></i> -
            <br>
            Tom - (V)
          </div>
          
          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'L' })">
            <i class="fa fa-tachometer"></i> -
            <br>
            Vel. - (L)
          </div>

          <div class="virtual-key" v-on:click="this.handleKeyPress({ key: 'Q' }); this.vkopen = false;">
            <i class="fa fa-stop"></i>
            <br>
            Parar (Q)
          </div>


          <div class="virtual-key" v-on:click="() => {
            if (!this.loaded) {
              this.handleKeyPress({ key: 'ENTER' });
            }
            else {
              this.handleKeyPress({ key: 'R' });
            }
            this.vkopen = false;
          }
            ">
            <i class="fa fa-play" v-if="!this.playing"></i>
            <i class="fa fa-pause" v-if="this.playing"></i>
            <br>
            <span v-if="!this.playing">Play (R)</span>
            <span v-if="this.playing">Pause (R)</span>
          </div>
         

        </div>
      </div>
    </div>



  </div>


  <!-- fullscreen button -->
  <div class="bt-fullscreen no-select" @click="() => { this.toggleFullscreen() }"
    style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 60px; height: 60px; border-radius: 12px; position: fixed; right: 80px; color: #333; background-color: #fff; z-index: 999; font-size: 10px; text-align: center;"
    :style="{ bottom: (!this.vkopen ? '10px' : 'unset'), top: (this.vkopen ? '10px' : 'unset') }">
    <vue-feather type="maximize"></vue-feather>
    Fullscreen
  </div>

  <!-- virtual keyboard opener -->
  <div class="bt-keyboard-opener no-select" @click="() => { this.vkopen = !this.vkopen; }"
    style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 60px; height: 60px; border-radius: 12px; position: fixed; right: 10px; color: #333; background-color: #fff; z-index: 999; font-size: 10px; text-align: center;"
    :style="{ bottom: (!this.vkopen ? '10px' : 'unset'), top: (this.vkopen ? '10px' : 'unset') }">
    <vue-feather type="command"></vue-feather>
    Teclado
  </div>


  <!-- <textarea name="keyboardhiddenfield" id="keyboardhiddenfield" ref="keyboardhiddenfield" autocomplete="off"
    @input="virtualKeyPress" @focus="disableKeyListener" @blur="enableKeyListener"
    style="width: 100%; height: 50px; position: fixed; top: 50%; left: 0; z-index: -1; opacity: 0; text-align: center;">
  </textarea> -->


</template>


<script>
import { watch, computed, ref } from 'vue';

import { mapState, useStore } from 'vuex';

import axios from 'axios';

import Footer from '../components/footer.vue';
import KaraokeflixPlayer from '../components/karaokeflixplayer/karaokeflixplayer.vue'

import { createToaster } from "@meforma/vue-toaster";

import QrcodeVue from 'qrcode.vue'

const toaster = createToaster({ position: 'bottom' });





export default {

  components: {
    Footer,
    KaraokeflixPlayer,
    QrcodeVue,
  },
  data() {
    return {
      vkopen: false,
      windowHeight: 0,
      shouldFadeOutScore: false,
      songUrl: null,
      songlist: [],
      queue: [],
      loaded: false,
      playing: false,
      showNextSong: false,
      showIntro: true,
      showScore: false,
      score: null,
      counter: 0,
      codeResult: null,
      showDigits: true,
      digitsCursorPosition: 0,
      // song cards carousel settings
      settings: {
        itemsToShow: 1,
        itemsToScroll: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  mounted() {

    this.updateWindowHeight();
    window.addEventListener('resize', this.updateWindowHeight); // Atualiza a altura quando a janela é redimensionada

    document.addEventListener('keydown', this.handleKeyPress);

    this.$store.dispatch('setShortcuts', true);





    setTimeout(() => {
      this.showIntro = false;
      toaster.clear();
      toaster.show(`Use o teclado virtual ou físico para controlar o Karaoke`);
    }, 1500)




  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowHeight); // Limpa o evento de resize ao destruir o componente

    document.removeEventListener('keydown', this.handleKeyPress);

  },
  computed: {
    ...mapState({
      playStatus: (state) => state.playStatus,
      credits: (state) => state.credits,
      creditsMode: (state) => state.creditsMode,
      scoreMode: (state) => state.scoreMode,
      shortcuts: (state) => state.shortcuts,
    }),
  },
  watch: {
    showIntro(newValue, oldValue) {
      console.log('watch showIntro !!!!!!!!!!', newValue, oldValue);
      document.getElementById('digit-0').focus();
    },


    'playStatus': {
      handler: function (newValue, oldValue) {
        // Faça algo com a nova e antiga valor do estado 'myState'
        console.log('watch playStatus !!!!!!!!!!', newValue, oldValue);
        if (newValue === true) {
          //this.loadSong()
        }
        else {

        }
      }
    },


  },

  methods: {
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
      if (this.$refs.player) {
        this.$refs.player.updateDimensions()
      }
    },

    getTitle() {



      if (this.queue.length === 0 && this.codeResult === null) {
        return "DIGITE O NÚMERO DA MÚSICA"
      }
      if (this.codeResult !== null) {
        return this.codeResult[2] + " - " + this.codeResult[0]
      }
      if (this.queue.length === 1) {
        return this.currentSong[2] + " - " + this.currentSong[0]
      }
      if (this.queue.length > 1) {
        var next = "";
        var nextQueue = [...this.queue];
        nextQueue.shift();
        nextQueue.forEach((item, index) => {
          next += item[2] + " - " + item[0] + " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ";
        })
        if (next != "") {
          next = " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; " + (nextQueue.length > 1 ? "Próximas" : "Próxima") + " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; " + next;
        }
        next = next != "" ? "<p>" + this.currentSong[2] + " - " + this.currentSong[0] + next + "</p>" : this.currentSong[2] + " - " + this.currentSong[0];
        next = next.trim();
        return next;
      }


    },


    async loadSong() {

      this.updateWindowHeight();

      this.showNextSong = true;

      this.loaded = false;

      if (this.queue.length > 0) {
        this.currentSong = this.queue[0]
      }

      this.currentSong = this.queue[0]


      console.log('loadSong', this.currentSong, this.queue)



      if (!this.creditsMode || (this.creditsMode && this.credits > 0)) {



        if (this.$refs.player) {
          this.$refs.player.destroyPlayer()
        }

        this.$store.dispatch('setLoading', true)

        if (this.currentSong && this.currentSong[1]) {
          var response = await this.requestSongUrl();
          if (response.song && response.song.includes('.karaokeflix')) {

            console.log('loadSong .karaokeflix', this.currentSong)

            this.loaded = true;
            //this.songUrl = response.song
            this.$refs.player.callSong(response.song)

            this.codeResult = null;
            this.resetDigits();


            if (this.creditsMode) {
              this.$store.dispatch('removeCredits', 1)
            }

            this.showDigits = false;


          }
          else if (response.mode == 'cooldown') {
            this.$store.dispatch('setLoading', false)
            toaster.error(`Oops! Você atingiu o limite de músicas carregadas em 1 hora! Por favor tente novamente mais tarde.`)


            this.queue.shift();


          }
          else {
            this.$store.dispatch('setLoading', false)

            toaster.error(`Oops! Música não localizada, por favor entre em contato com a nossa equipe de suporte!`)

            console.log('NOT LOCATED', this.queue)

            this.queue.shift();

            this.showIntro = false;
            this.showNextSong = false;
            this.showScore = false;



          }
        } else {
          this.$store.dispatch('setLoading', false)

          toaster.error(`Oops! Nenhuma música para tocar!`)


          this.showIntro = false;
          this.showNextSong = false;
          this.showScore = false;

        }



      }
      else {

        toaster.error(`Por favor, insira créditos para cantar`)



        this.showIntro = false;
        this.showNextSong = false;
        this.showScore = false;


      }
    },



    nextSong() {

      if (this.$refs.player) {
        this.$refs.player.destroyPlayer()
      }

      this.playing = false
      this.loaded = false


      console.log('nextSong')
      this.$store.dispatch('setPlayStatus', false);



      if (this.queue.length > 0) {

        this.showNextSong = true;

        //PLAYNOW
        this.loadSong()

      }
      else {

        //toaster.error(`Sem mais músicas para tocar.`)

        this.showIntro = false;
        this.showNextSong = false;
        this.showScore = false;

      }



    },





    /* virtualKeyPress(ev) {
      console.log('virtualKeyPress ev', ev);
      let key = "";

      if (ev.inputType === 'insertLineBreak') {
        key = "ENTER";
      } else {
        key = ev.data;
      }
      console.log('virtualKeyPress key', key)

      document.getElementById('keyboardhiddenfield').value = ''

      if (isNaN(key)) {
        document.getElementById('keyboardhiddenfield').blur();
      }

      let event = {
        key,
        shiftKey: false
      }
      this.handleKeyPress(event)
    }, */


    disableKeyListener() {
      document.removeEventListener('keydown', this.handleKeyPress);
    },

    enableKeyListener() {
      document.addEventListener('keydown', this.handleKeyPress);
    },




    handleKeyPress(event) {
      const pressedKey = event.key.toString().toUpperCase();
      console.log('pressedKey KARAOKE:', pressedKey, event.shiftKey, this.shortcuts);


      if (pressedKey == "ESCAPE") {
        this.$refs.player.escapeHome();
      }
      else {
        if (this.shortcuts === true) {
          if (event.shiftKey) {
            switch (pressedKey) {
              case "ARROWRIGHT":

                this.$refs.player.stopSong();

                break;
              //case "ARROWLEFT": this.previousSong(); break;
              case "P": this.removeCredit(); break;
            }
          }
          else {
            switch (pressedKey) {
              //PLAYER
              //case "ENTER": this.$refs.player.playSong(); break;
              case "Q": this.$refs.player.stopSong(); break;
              case "R": this.$refs.player.togglePlay(); break;
              case "W": this.$refs.player.restartSong(); break;
              case "M": this.$refs.player.volumeUp(); break;
              case "N": this.$refs.player.volumeDown(); break;
              case "B": this.$refs.player.updateCdgBackground(); break;

              case "A": this.$refs.player.pitchUp(); break;
              case "V": this.$refs.player.pitchDown(); break;
              //
              case "K": this.$refs.player.rateUp(); break;
              case "L": this.$refs.player.rateDown(); break;

              case "F": this.toggleFullscreen(); break;
              case "P": this.addCredit(); break;
              case "T": this.setTopScore(); break;
              case "C": this.toggleCreditsMode(); break;
              case "S": this.toggleScoreMode(); break;
              case "Z": this.resetCredits(); break;

              //case " ": if (!this.loaded) this.loadSong(); break;
              case "U": this.resetDigits(); break;

              case "0": this.digitsPressed(0); break;
              case "1": this.digitsPressed(1); break;
              case "2": this.digitsPressed(2); break;
              case "3": this.digitsPressed(3); break;
              case "4": this.digitsPressed(4); break;
              case "5": this.digitsPressed(5); break;
              case "6": this.digitsPressed(6); break;
              case "7": this.digitsPressed(7); break;
              case "8": this.digitsPressed(8); break;
              case "9": this.digitsPressed(9); break;

              case "ENTER":

                if (this.codeResult !== null) {
                  this.addToQueue();
                }
                if (!this.loaded) {
                  this.loadSong();
                }


                break;


              case " ":

                if (this.codeResult !== null) {
                  this.addToQueue();
                }
                if (!this.loaded) {
                  this.loadSong();
                }


                break;

              //case " ": this.loadSong(); break;


            }
          }
        }
      }


    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen().then(() => {
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock("landscape").catch(err => {
              console.error("Erro ao travar a orientação:", err);
            });
          } else {
            console.warn("API de bloqueio de orientação não suportada.");
          }
        });
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    addToQueue() {

      this.queue.push(this.codeResult);
      this.showDigits = false;
      this.codeResult = null;
      this.resetDigits();
      if (this.loaded) {
        toaster.success(`Música adicionada com sucesso!`)
      }

    },
    digitsPressed(pressedKey) {

      this.showDigits = true;

      console.log('digitsPressed', pressedKey);

      var index = this.digitsCursorPosition || 0;

      document.getElementById("digit-" + index).value = pressedKey;

      this.findCode()


      var next = index < 5 ? index + 1 : (this.codeResult !== null ? null : 0);


      this.updateDigitsCursorPosition(next);

      //if (next !== null) document.getElementById('digit-' + next).focus();


    },
    updateDigitsCursorPosition(pos) {
      console.log('updateDigitsCursorPosition', pos);
      //this.blurDigits();
      this.digitsCursorPosition = pos;
    },
    blurDigits() {
      document.getElementById('digit-0').blur();
      document.getElementById('digit-1').blur();
      document.getElementById('digit-2').blur();
      document.getElementById('digit-3').blur();
      document.getElementById('digit-4').blur();
      document.getElementById('digit-5').blur();
    },
    resetDigits() {
      this.showDigits = false;
      this.blurDigits()
      this.digitsCursorPosition = 0;
      this.codeResult = null;
      document.getElementById('digit-0').value = '';
      document.getElementById('digit-1').value = '';
      document.getElementById('digit-2').value = '';
      document.getElementById('digit-3').value = '';
      document.getElementById('digit-4').value = '';
      document.getElementById('digit-5').value = '';
    },
    findCode() {

      var code = document.getElementById('digit-0').value + '' + document.getElementById('digit-1').value + '' + document.getElementById('digit-2').value + '' + document.getElementById('digit-3').value + '' + document.getElementById('digit-4').value + '' + document.getElementById('digit-5').value + '';

      var results = [...this.$store.state.songlist.songs].filter(item => this.filterCode(item, code));

      console.log('findCode', results)

      if (results.length === 1) {

        this.codeResult = results[0]

        //this.resetDigits();

      }
      else {

        this.codeResult = null


      }

    },
    interface(e) {
      //console.log('interface', e);
      switch (e) {
        case 'nextSong':
          this.queue.shift();
          this.score = null;
          this.showScore = false;
          this.nextSong();
          break;
        //case 'nextSong': this.nextSong(); break;
        //case 'previousSong': this.previousSong(); break;
        case 'playing': this.playing = true; break;
        case 'stoping': this.playing = false; this.loaded = false; break;
        case 'showNextSong': this.showNextSong = true; break;
        case 'hideNextSong': this.showNextSong = false; break;
        case 'finishedSong': this.finishedSong(); break;
      }
    },
    finishedSong() {
      //this.queue.shift();
      this.loaded = false;
      this.playing = false;
      if (this.scoreMode == true) {
        this.showScore = true;
        this.triggerScore();
        console.log('showScore');
      }
      else {
        this.queue.shift();
        this.score = null;
        this.showScore = false;
        this.nextSong();
      }
    },

    randomScore() {
      const random = Math.random(); // Gera um número aleatório entre 0 e 1
      if (random < 0.2) {
        // 20% de chance de retornar um número entre 70 e 80
        return Math.floor(Math.random() * 10) + 70;
      } else if (random < 0.6) {
        // 40% de chance de retornar um número entre 80 e 90
        return Math.floor(Math.random() * 10) + 80;
      } else {
        // 40% de chance de retornar um número entre 90 e 99
        return Math.floor(Math.random() * 10) + 90;
      }
    },
    triggerScore() {
      //const counterElement = document.getElementById("counter");

      if (this.score !== 100) {
        this.score = this.randomScore()
      }

      if (this.score === 100) {
        this.scoreVideo = 'https://karaokeflix.nodespace.io/backgrounds/score/bg-score-high.mp4';
      }
      else if (this.score >= 81 && this.score <= 99) {
        this.scoreVideo = 'https://karaokeflix.nodespace.io/backgrounds/score/bg-score-med.mp4';
      }
      else {
        this.scoreVideo = 'https://karaokeflix.nodespace.io/backgrounds/score/bg-score-low.mp4';
      }

      const finalValue = this.score;

      const duration = 5000;

      const intervalCounter = duration / finalValue;

      let currentValue = 0;

      const updateCounter = () => {
        currentValue++;
        if (currentValue <= finalValue) {
          this.counter = Math.round(currentValue);
          setTimeout(updateCounter, intervalCounter);
        }
        else {
          this.counter = finalValue;

          if (finalValue == 100) {
            setTimeout(() => {
              this.shouldFadeOutScore = true;
            }, 2000)
          }

          setTimeout(() => {
            this.shouldFadeOutScore = false;
            this.queue.shift();
            this.score = null;
            this.showScore = false;
            this.nextSong();
          }, finalValue == 100 ? 19000 : 6000);
        }

      };

      updateCounter();





      /* const minSpeed = 100; // Velocidade mínima (10 número por segundo)
      const maxSpeed = 50; // Velocidade máxima (20 números por segundo)
      let duration = 4000; // Duração inicial em milissegundos
      let interval = 10; // Intervalo de atualização em milissegundos
      let step = (finalValue * interval) / duration;
      let currentValue = 0;

      const updateCounter = () => {

        currentValue += step;
        if (currentValue <= finalValue) {
          this.counter = Math.round(currentValue);

          // Aumentar a velocidade até o limite máximo
          if (currentValue < finalValue / 4 && interval > maxSpeed) {
            duration /= 1.05; // Acelerar
            interval /= 1.05;
            step = (finalValue * interval) / duration;
          }
          // Diminuir a velocidade até o limite mínimo
          else if (currentValue >= (finalValue * 3) / 4 && interval < minSpeed) {
            duration *= 1.025; // Desacelerar
            interval *= 1.025;
            step = (finalValue * interval) / duration;
          }

          setTimeout(updateCounter, interval);
        } else {
          this.counter = finalValue;


          setTimeout(() => {

            this.queue.shift();

            this.score = null;
            this.showScore = false;
            this.nextSong();
          }, 5000);


        }

      };

      updateCounter(); */

    },



    filterCode(item, code) {

      if (item[1].toString() === code.toString()) {
        return true
      }
      return false

    },


    toggleScoreMode() {
      this.$store.dispatch('setScoreMode', !this.scoreMode);
      toaster.success(`Modo de Pontuação ${this.scoreMode ? 'Ativado' : 'Desativado'}`)

    },
    toggleCreditsMode() {
      this.$store.dispatch('setCreditsMode', !this.creditsMode);
    },
    addCredit() {
      if (this.creditsMode) this.$store.dispatch('addCredits', 1)
    },
    removeCredit() {
      if (this.creditsMode) this.$store.dispatch('removeCredits', 1)
    },
    resetCredits() {
      this.$store.dispatch('resetCredits');
    },
    setTopScore() {
      this.score = 100;
    },


    async requestSongUrl() {
      console.log('this.currentSong[1]', this.currentSong[1]);
      var data = null
      var params = {
        code: this.currentSong[1]
      }
      var token = localStorage.getItem("user_jwt")
      var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
      //var config = { headers: { 'Content-type': 'multipart/form-data' } }
      await axios
        .post(
          "https://karaokeflix.nodespace.io/api/karaokeflix/get.song.php",
          params,
          config
        )
        .then((response) => {
          console.log('response', response);
          if (response.data) {
            data = response.data
          }
        })
        .catch((error) => {
          console.log("error", error)
        });
      return data
    }



  },
  setup() {


    return {
      modules: [],
    };
  },
};
</script>


<style>
.no-select {
  user-select: none; /* Padrão moderno */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.virtual-keyboard-karaoke {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out;
}

.virtual-keyboard-inner {
  background-color: #eee;
  padding: 10px;
  border-radius: 12px;
}

.virtual-keyboard-numbers {
  display: flex;
  flex-direction: row;

}

.virtual-keyboard-controls {
  display: flex;
  flex-direction: row;
}

.virtual-key {
  flex: 1;
  padding: 10px;
  border: solid 1px #ddd;
  margin: 5px;
  text-align: center;
  border-radius: 10px;
  color: #333;
  font-weight: 700;
  cursor: pointer;
  font-size: 11px;
}

.virtual-keyboard-numbers .virtual-key {
  padding: 25px 0;
}

.virtual-key:hover,
.virtual-key:focus,
.virtual-key:active {
  background-color: #CCC;
}

.virtual-keyboard-karaoke.open {
  transform: translateY(0%);
}



@media(max-width: 991px) {
  .virtual-keyboard-karaoke {
    padding: 5px;
  }

  .virtual-keyboard-inner {
    padding: 2px;
  }

  .virtual-key {
    margin: 1px;
    padding: 2px 2px;
    font-size: 8px;
  }

  .virtual-keyboard-numbers .virtual-key {
    padding: 10px 0;
  }
}





.page-wrapper.advance-layout .page-header {
  background-color: none !important;
}





.digits {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.digit-wrapper {}


.digit-wrapper input:focus,
.digit-wrapper input:focus::placeholder {
  outline: none !important;
}

.digit-wrapper input.active-digit,
.digit-wrapper input.active-digit::placeholder {
  /* -webkit-text-fill-color: #F56601 !important; */
}

.digit-wrapper input,
.digit-wrapper input::placeholder {
  font-size: 10vw;
  width: 10vw;
  padding: 0;
  font-weight: 900;
  text-align: center;
  color: #FFF;
  /* Will override color (regardless of order) */
  /* -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: black; */
  caret-color: transparent !important;
  background: rgba(0, 0, 0, 0);
  border: none;
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-3px -3px 0px rgba(0, 0, 0, 0.5)) drop-shadow(3px -3px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-3px 3px 0px rgba(0, 0, 0, 0.5));

}

.digit-wrapper input {
  color: #e5c990;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}




.songtitle.active-title {
  color: #F56601;
}

.songtitle {
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  /* -webkit-text-fill-color: #FFF; */
  /* Will override color (regardless of order) */
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5));
}




@keyframes blink {

  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.label-credits,
.label-no-credits {
  font-size: 32px;
  font-weight: 900;
  color: #fff;
  /* Will override color (regardless of order) */
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5));

}

.label-no-credits {
  animation: blink 1s infinite;
}







.featured-input {
  z-index: 101;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.headed-input {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 90px;
  padding: 20px;
}

.headed-input .digits {
  position: absolute;
  background-color: rgba(0, 0, 0, .95);
  border-radius: 10px;
  width: auto;
  padding: 0 10px;
  z-index: 102;
}




.headed-input .songtitle {
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: 101;

  -webkit-mask-image: linear-gradient(to right, transparent, black 400px, transparent),
    linear-gradient(to left, transparent, black 400px, transparent);
  mask-image: linear-gradient(to right, transparent, black 400px, transparent),
    linear-gradient(to left, transparent, black 400px, transparent);
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-150%);
  }
}

.headed-input .songtitle p {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 32px;
  animation: marquee 12s linear infinite;
  text-wrap: nowrap;
  font-weight: 900;
}


.headed-input .digit-wrapper input {
  font-size: 32px;
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5));

}

.headed-input .digit-wrapper input,
.headed-input .digit-wrapper input::placeholder {
  font-size: 32px;
  width: 32px;
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5));
}



.qr-wrapper {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 101;
  background-color: #fff;
  border-radius: 12px;
  border: solid 1px #000;
  padding: 10px;
}

.qr-wrapper p {
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  line-height: 12px;
  margin-bottom: 5px;
}



.animated-div {
  transition: opacity 1s ease;
  /* Tempo de transição da opacidade */
  opacity: 1;
  /* Visível */
}

.fadeOut {
  opacity: 0;
  /* Invisível */
}




/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}



@media(max-width: 991px) {



  .intro-wrapper .intro-featured {
    font-size: 16px !important;
    text-align: center !important;
    letter-spacing: 10px !important;
  }

  .intro-wrapper .intro-site {
    font-size: 14px !important;
    letter-spacing: 2px !important;
  }




  .qr-wrapper {
    display: none !important;
  }

  .songtitle,
  .songtitle p {
    font-size: 18px !important;
  }

  .digit-wrapper input,
  .digit-wrapper input::placeholder {
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(1px -1px 0px rgba(0, 0, 0, 0.5)) drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5));
    font-size: 50px;
  }

  .headed-input {
    padding: 5px 0;
  }

  .headed-input .songtitle,
  .headed-input .songtitle p {
    font-size: 14px !important;
  }

  .headed-input .digits {
    margin: 10px;
  }

  .nextsong-wrapper h1 {
    font-size: 30px !important;
  }

  .nextsong-wrapper h2 {
    font-size: 20px !important;
  }

  .headed-input .songtitle p {
    animation: marquee 8s linear infinite;
  }

  .score-wrapper {
    margin-top: 20vh !important;
  }

  .score-wrapper #counter {
    font-size: 25vh !important;
  }

  @keyframes marquee {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(-200%);
    }

  }

}
</style>